.FilterSection_header___5RF2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.TreatmentCardPrice_container__IvxE2 {
    display: flex;
    gap: 8px;
    align-items: baseline;
}

.TreatmentCardPrice_prefix__45Q_y {
    display: block;
    text-transform: capitalize;
}

.TreatmentCardPrice_price__jtI_j {
    display: inline-block;
    font-size: 18px;
    line-height: 100%;
    color: #007ACC;
    vertical-align: bottom;
}

@container (min-width: 310px) {

.TreatmentCardPrice_price__jtI_j {
        font-size: 18px
}
    }

.FilterTagCloud_cloud__lyzZQ {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
}

.FilterTagCloud_tag__dH11G {
    display: flex !important;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
}

.FilterTagCloud_button__0nig_ {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: -4px;
    margin-right: -4px;

    padding: 4px;
}

.SortAndFilterDialog_dialog__ldG_t {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100vw;
    height: 100dvh;

}

    @media (min-width: 768px) {.SortAndFilterDialog_dialog__ldG_t {
        align-items: center

}
    }

.SortAndFilterDialog_panel__WMZqg {
    display: flex;
    flex-direction: column;
    max-width: 38.75rem;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background-color: #FFFFFF;
    
    overflow: hidden;
    transition: transform 300ms ease-in-out;
}

@media (min-width: 768px) {

.SortAndFilterDialog_panel__WMZqg {
        max-height: calc(100% - 10.125rem);
        border-radius: 24px;
}
    }

.SortAndFilterDialog_panel__WMZqg {

    z-index: 0;

}

.SortAndFilterDialog_panelWindowHeight__AmOdw {
    height: calc(100% - 3.25rem);
}

.SortAndFilterDialog_header__5tcsB {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 16px;
    border-bottom: 1px solid #CAD8E2;
    flex-grow: 0;
    flex-shrink: 0;
}

.SortAndFilterDialog_closeButton__I4Inu {
    margin: -8px;

    padding: 8px;

}

