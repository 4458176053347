.container {
    display: flex;
    gap: theme('padding.050');
    align-items: baseline;
}

.prefix {
    display: block;
    text-transform: capitalize;
}

.price {
    display: inline-block;
    font-size: theme('fontSize.200');
    line-height: theme('lineHeight.none');
    color: theme('colors.products.price');
    vertical-align: bottom;

    @container (min-width: 310px) {
        font-size: theme('fontSize.200');
    }
}
